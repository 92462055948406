/* eslint-disable */
.DokaOverlay {
  position: relative;
  overflow: hidden; }

.DokaOverlay > img {
  display: block;
  width: 100%;
  height: auto; }

.DokaOverlay > .DokaContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }
