/*!
 * Doka Image Editor 6.15.4
 * (c) 2018-2020 PQINA Inc. - All Rights Reserved
 * License: https://pqina.nl/doka/license/
 */
/* eslint-disable */
.doka--root, :root {
  --doka-effect--invert:0%;
  --doka-value--alpha:calc(100% - var(--doka-effect--invert));
  --doka-value--beta:var(--doka-effect--invert);
  --doka-color--alpha:#ffd843;
  --doka-color--alpha-dim:#ffc343;
  --doka-font-size:16px;
  --doka-font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  --doka-editor--max-width:80em;
  --doka-editor--max-height:60em;
  --doka-editor--spacing:0;
  --doka-modal--width:70vw;
  --doka-modal--height:80vh;
  --doka-modal--max-width:56rem;
  --doka-modal--max-height:40rem;
  --doka-effect--frost:blur(10px);
  --doka-color--beta:hsl(0,0%,var(--doka-value--alpha));
  --doka-color--gamma:hsl(0,0%,var(--doka-value--beta));
  --doka-color--delta:hsla(0,0%,var(--doka-value--beta),.85);
  --doka-color--epsilon:hsla(0,0%,var(--doka-value--beta),.75);
  --doka-editor--color:hsla(0,0%,var(--doka-value--alpha),.9);
  --doka-editor--color-dim:hsla(0,0%,var(--doka-value--alpha),.3);
  --doka-editor--background-color:hsla(0,0%,var(--doka-value--beta),.99);
  --doka-editor--background-ellipse-inner-color:hsla(0,0%,var(--doka-value--alpha),.15);
  --doka-editor--background-ellipse-outer-color:hsla(0,0%,var(--doka-value--alpha),0);
  --doka-editor-outline--color:hsl(0,0%,var(--doka-value--alpha));
  --doka-modal--background:hsl(0,0%,var(--doka-value--beta));
  --doka-modal--overlay:hsla(0,0%,var(--doka-value--beta),.8);
  --doka-modal--shadow:0 1em 4em rgba(0,0,0,.25),0 .5em 2em rgba(0,0,0,.25);
  --doka-scrollbar-rail--color:var(--doka-color--delta);
  --doka-scrollbar-thumb--color:var(--doka-color--epsilon);
  --doka-scroller-rail--color:transparent;
  --doka-scroller-thumb--color:hsla(0,0%,var(--doka-value--alpha),.2);
  --doka-component--color:var(--doka-editor--color);
  --doka-component--background:hsla(0,0%,var(--doka-value--alpha),.15);
  --doka-component--background-dim:hsla(0,0%,var(--doka-value--alpha),.1);
  --doka-component--background-dark:hsla(0,0%,var(--doka-value--beta),.8);
  --doka-component--background-darker:hsla(0,0%,var(--doka-value--beta),.9);
  --doka-component-alt--color:var(--doka-color--delta);
  --doka-component-alt--color-dim:hsla(0,0%,var(--doka-value--beta),.75);
  --doka-component-alt--background:hsla(0,0%,var(--doka-value--alpha),.85);
  --doka-component-alt-effect--background:hsla(0,0%,var(--doka-value--alpha),.6);
  --doka-component-alt--edge:hsla(0,0%,var(--doka-value--beta),.15);
  --doka-component-alt--shadow:0 .75em 1em -.75em rgba(0,0,0,.4),0 1em 2em 0 rgba(0,0,0,.05);
  --doka-button--color:var(--doka-component--color);
  --doka-button--background:var(--doka-component--background);
  --doka-button--background-dim:var(--doka-component--background-dim);
  --doka-button-hover--background:hsla(0,0%,var(--doka-value--alpha),.25);
  --doka-button-alt--color:var(--doka-component-alt--color);
  --doka-button-alt--background:var(--doka-component-alt--background);
  --doka-button-alt-hover--background:hsla(0,0%,var(--doka-value--alpha),.95);
  --doka-button-alt-effect--background:var(--doka-component-alt-effect--background);
  --doka-button-alt-effect-hover--background:hsla(0,0%,var(--doka-value--alpha),.75);
  --doka-button-primary--color:var(--doka-color--gamma);
  --doka-button-primary--background:var(--doka-color--alpha);
  --doka-button-primary-hover--background:var(--doka-color--alpha-dim);
  --doka-button-main--radius:9999em;
  --doka-button-tab--radius:0;
  --doka-button-tool--radius:0;
  --doka-dropdown-hover--background:hsla(0,0%,var(--doka-value--beta),.1);
  --doka-dropdown-hover-active--background:hsla(0,0%,var(--doka-value--beta),.15);
  --doka-dropdown-selected--color:var(--doka-editor--color);
  --doka-dropdown-selected--background:hsla(0,0%,var(--doka-value--beta),.9);
  --doka-dropdown-selected-hover--background:hsla(0,0%,var(--doka-value--beta),.8);
  --doka-dropdown-selected-active-hover--background:hsla(0,0%,var(--doka-value--beta),.6);
  --doka-range-input--background:hsla(0,0%,var(--doka-value--alpha),.2);
  --doka-range-input-recenter--background:var(--doka-range-input--background);
  --doka-range-input-centered--background:var(--doka-range-input--background);
  --doka-range-input-thumb--color:hsl(0,0%,var(--doka-value--alpha));
  --doka-range-input-thumb-hover--color:hsla(0,0%,var(--doka-value--alpha),.15);
  --doka-tab--border-color:rgba(0,0,0,.15);
  --doka-crop-focal-line--color:hsla(0,0%,100%,.5);
  --doka-crop-corner--color:var(--doka-color--beta);
  --doka-crop-corner-focus--color:var(--doka-color--beta);
  --doka-crop-corner--shadow:0 .0625em .125em rgba(0,0,0,.2);
  --doka-crop-edge-focus--color:hsla(0,0%,var(--doka-value--alpha),.5);
  --doka-image-outline--color:var(--doka-color--beta);
  --doka-image-focus--color:hsla(0,0%,var(--doka-value--alpha),.25);
  --doka-status--background:hsla(0,0%,var(--doka-value--alpha),.1);
  --doka-status-bubble--color:hsla(0,0%,var(--doka-value--beta),.8);
  --doka-status-bubble--color-dim:hsla(0,0%,var(--doka-value--beta),.2);
  --doka-status-bubble--background:hsla(0,0%,var(--doka-value--alpha),.75);
  --doka-status-bubble-effect--background:hsla(0,0%,var(--doka-value--alpha),.5);
  --doka-status-bubble--shadow:0 .125em 1em rgba(0,0,0,.2);
  --doka-size-indicator--color:rgba(0,0,0,.85);
  --doka-size-indicator--background:hsla(0,0%,100%,.95);
  --doka-size-indicator--edge:rgba(0,0,0,.15);
  --doka-size-indicator--radius:.25em;
  --doka-button-zoom--color:rgba(0,0,0,.85);
  --doka-button-zoom--background:hsla(0,0%,100%,.85);
  --doka-button-zoom-hover--background:hsla(0,0%,100%,.95);
  --doka-button-zoom-effect--background:hsla(0,0%,100%,.6);
  --doka-button-zoom-effect-hover--background:hsla(0,0%,100%,.75);
  --doka-filter-tile--radius:0;
  --doka-filter-tile-selected--color:var(--doka-color--alpha);
  --doka-markup-manipulator--fill:#2990ff;
  --doka-markup-manipulator--stroke:#fff;
  --doka-markup-manipulator--stroke-inverted:#000;
  --doka-markup-manipulator--line:hsla(0,0%,100%,.75);
  --doka-markup-manipulator--line-inverted:rgba(0,0,0,.75);
  --doka-instruction-bubble--color:#fff;
  --doka-instruction-bubble--background:radial-gradient(closest-side ellipse,rgba(0,0,0,.22) 20%,rgba(0,0,0,.21) 35%,rgba(0,0,0,.18) 50%,rgba(0,0,0,.1) 70%,rgba(0,0,0,.05) 80%,rgba(0,0,0,.01) 90%,transparent);
  --doka-instruction-bubble--color-shadow:0 .125em .75em #000;
  --doka-menu--offset:12em; }

.doka--root button, .doka--root fieldset, .doka--root input, .doka--root label, .doka--root legend {
  all: initial;
  font-family: inherit;
  font-weight: inherit;
  color: inherit; }

.doka--root *, .doka--root :after, .doka--root :before {
  box-sizing: inherit; }

.doka--root button {
  box-sizing: border-box;
  text-align: center; }

.doka--root .doka--view-stack {
  pointer-events: none; }

.doka--root .doka--view-stack > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  outline: transparent; }

.doka--root .doka--view-stack [data-view-active=false] * {
  pointer-events: none !important; }

.doka--root .doka--view-stack [data-view-active=true] {
  z-index: 1; }

.doka--root .doka--view-stack [data-view-hidden=true] {
  display: none !important; }

.doka--root .doka--button-app {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border: none;
  font-family: inherit;
  font-size: 1em;
  outline: transparent;
  color: rgba(255, 255, 255, 0.9);
  color: var(--doka-button--color);
  background-color: rgba(255, 255, 255, 0.15);
  background-color: var(--doka-button--background);
  border-radius: 9999em;
  border-radius: var(--doka-button-main--radius);
  display: flex;
  align-items: center;
  line-height: 2.5;
  padding: 0 1.5em;
  font-weight: 500;
  transition: transform .15s ease-out,background-color .25s ease-out,color .25s ease-out;
  transform: scale(1); }

.doka--root .doka--button-app svg {
  margin: 0 auto;
  display: inline-block;
  width: auto;
  height: auto; }

.doka--root .doka--button-app svg + span {
  margin-left: .5em; }

.doka--root .doka--button-app span {
  font-size: .875em; }

.doka--root .doka--button-app:focus, .doka--root .doka--button-app:hover {
  background-color: rgba(255, 255, 255, 0.25);
  background-color: var(--doka-button-hover--background); }

.doka--root .doka--button-app:active {
  transition-duration: .25s;
  transform: scale(0.95); }

@supports (-webkit-backdrop-filter: var(--doka-effect--frost)) or (backdrop-filter: var(--doka-effect--frost)) {
  .doka--root .doka--button-app {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: var(--doka-effect--frost);
    backdrop-filter: var(--doka-effect--frost); } }

.doka--root .doka--button-app.doka--button-icon-only {
  width: 2.25em;
  height: 2.25em;
  padding: 0; }

.doka--root .doka--button-action-reset svg {
  max-width: 1.625em; }

.doka--root .doka--button-action-confirm {
  color: #000;
  color: var(--doka-button-primary--color);
  background-color: #ffd843;
  background-color: var(--doka-button-primary--background);
  font-weight: 600;
  -webkit-backdrop-filter: none;
  backdrop-filter: none; }

.doka--root .doka--button-action-confirm:focus, .doka--root .doka--button-action-confirm:hover {
  background-color: #ffc343;
  background-color: var(--doka-button-primary-hover--background); }

.doka--root[data-style-viewport~=x-cramped][data-style-viewport~=multi-util] .doka--button-app {
  font-size: .85em;
  -webkit-backdrop-filter: none;
  backdrop-filter: none; }

.doka--root[data-style-viewport~=x-cramped][data-style-viewport~=multi-util] .doka--button-app svg {
  width: 1.25em;
  height: 1.25em; }

.doka--root[data-style-viewport~=x-cramped][data-style-viewport~=multi-util] .doka--button-action-confirm {
  font-size: .75em; }

.doka--root[data-style-viewport~=x-cramped][data-style-viewport~=multi-util] .doka--button-app:not(.doka--button-action-confirm) {
  color: rgba(255, 255, 255, 0.9);
  color: var(--doka-button--color);
  background-color: transparent; }

.doka--root[data-style-viewport~=x-cramped][data-style-viewport~=multi-util] .doka--button-app.doka--button-icon-only svg {
  width: 1.75em;
  height: 1.75em; }

.doka--root[data-style-viewport~=x-cramped][data-style-viewport~=multi-util] .doka--button-app.doka--button-icon-fallback {
  width: 2.5em;
  height: 2.5em;
  padding: 0; }

.doka--root[data-style-viewport~=x-cramped][data-style-viewport~=multi-util] .doka--button-app.doka--button-icon-fallback span {
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden; }

.doka--root .doka--button-icon-fallback svg {
  display: none; }

.doka--root .doka--button-icon-fallback svg + span {
  margin-left: 0; }

.doka--root[data-style-viewport*=x-cramped][data-style-viewport*=multi-util] .doka--button-icon-fallback svg {
  display: block; }

.doka--root[data-style-viewport*=x-cramped][data-style-viewport*=multi-util] .doka--button-icon-fallback svg + span {
  margin-left: 0; }

.doka--root .doka--button-icon-only {
  flex-shrink: 0; }

.doka--root .doka--button-icon-only span {
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden; }

.doka--root .doka--button-tab {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border: none;
  font-family: inherit;
  font-size: 1em;
  outline: transparent;
  line-height: inherit;
  color: rgba(255, 255, 255, 0.9);
  color: var(--doka-button--color);
  border-radius: 0;
  border-radius: var(--doka-button-tab--radius);
  width: 5em;
  max-width: 5em;
  padding-top: .875em;
  padding-bottom: .75em;
  background-color: transparent;
  font-weight: 500;
  transition: transform .15s ease-out,background-color .25s ease-out,color .25s ease-out;
  transform: scale(1); }

.doka--root .doka--button-tab svg {
  display: inline;
  margin-bottom: .325em;
  max-height: 1.5em; }

.doka--root .doka--button-tab span {
  display: block;
  font-size: .875em; }

.doka--root .doka--button-tab:focus, .doka--root .doka--button-tab:hover {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: var(--doka-effect--frost);
  backdrop-filter: var(--doka-effect--frost); }

.doka--root .doka--button-tab:focus, .doka--root .doka--button-tab:hover, .doka--root .doka--button-tab[data-active=true] {
  background-color: rgba(255, 255, 255, 0.15);
  background-color: var(--doka-button--background); }

.doka--root .doka--button-tab[data-active=true]:focus, .doka--root .doka--button-tab[data-active=true]:hover {
  background-color: rgba(255, 255, 255, 0.25);
  background-color: var(--doka-button-hover--background); }

.doka--root .doka--button-tab:active {
  transition-duration: .25s;
  transform: scale(0.95); }

.doka--root .doka--button-tab .doka--icon-resize-arrow-ne, .doka--root .doka--button-tab .doka--icon-resize-arrow-sw {
  transition: opacity .25s ease-out; }

.doka--root .doka--button-tab[data-scale-direction=down] .doka--icon-resize-arrow-ne {
  opacity: 0; }

.doka--root .doka--button-tab[data-scale-direction=down] .doka--icon-resize-arrow-sw, .doka--root .doka--button-tab[data-scale-direction=up] .doka--icon-resize-arrow-ne {
  opacity: 1; }

.doka--root .doka--button-tab[data-scale-direction=up] .doka--icon-resize-arrow-sw {
  opacity: 0; }

@supports (-webkit-backdrop-filter: var(--doka-effect--frost)) or (backdrop-filter: var(--doka-effect--frost)) {
  .doka--root .doka--button-tab[data-active=true] {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: var(--doka-effect--frost);
    backdrop-filter: var(--doka-effect--frost); } }

.doka--root .doka--utils[data-layout=compact] .doka--button-tab {
  height: 4.6875em; }

.doka--root .doka--utils[data-layout=compact] .doka--button-tab span {
  display: none; }

.doka--root .doka--utils[data-layout=compact] .doka--button-tab svg {
  margin-bottom: 0; }

.doka--root[data-style-viewport*=x-cramped] .doka--utils {
  padding: 0 1px; }

.doka--root[data-style-viewport*=x-cramped] .doka--utils[data-util-count="5"] .doka--button-tab {
  width: 20%; }

.doka--root[data-style-viewport*=x-cramped] .doka--utils[data-util-count="5"] .doka--button-tab span {
  display: none; }

.doka--root[data-style-viewport*=x-cramped] .doka--utils[data-util-count="4"] .doka--button-tab {
  width: 25%; }

.doka--root[data-style-viewport*=x-cramped] .doka--utils[data-util-count="3"] .doka--button-tab {
  width: 33.333%; }

.doka--root[data-style-viewport*=x-cramped] .doka--utils[data-util-count="2"] .doka--button-tab {
  width: 50%; }

.doka--root[data-style-viewport*=x-cramped] .doka--utils .doka--button-tab {
  min-width: 2em;
  max-width: 3.5em;
  height: 3.25em;
  padding: .325em 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 0 1px var(--doka-tab--border-color); }

.doka--root[data-style-viewport*=x-cramped] .doka--utils .doka--button-tab:first-child:before, .doka--root[data-style-viewport*=x-cramped] .doka--utils .doka--button-tab:last-child:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  pointer-events: none; }

.doka--root[data-style-viewport*=x-cramped] .doka--utils .doka--button-tab:first-child:before {
  box-shadow: -1px 0 0 0 rgba(0, 0, 0, 0.15);
  box-shadow: -1px 0 0 0 var(--doka-tab--border-color); }

.doka--root[data-style-viewport*=x-cramped] .doka--utils .doka--button-tab:last-child:before {
  box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.15);
  box-shadow: 1px 0 0 0 var(--doka-tab--border-color); }

.doka--root[data-style-viewport*=x-cramped] .doka--utils .doka--button-tab svg {
  width: 1em;
  margin: 0 auto -.125em; }

.doka--root[data-style-viewport*=x-cramped] .doka--utils .doka--button-tab span {
  font-size: .5625em;
  margin-top: .5em; }

.doka--root[data-style-viewport*=x-cramped] .doka--utils .doka--button-tab:active {
  transform: scale(1); }

.doka--root[data-style-viewport*=x-cramped] .doka--utils .doka--button-tab[data-active=true] {
  -webkit-backdrop-filter: none;
  backdrop-filter: none; }

.doka--root[data-style-viewport*=x-cramped] .doka--utils .doka--button-tab[data-active=true]:focus, .doka--root[data-style-viewport*=x-cramped] .doka--utils .doka--button-tab[data-active=true]:hover {
  background-color: rgba(255, 255, 255, 0.15);
  background-color: var(--doka-button--background); }

.doka--root .doka--button-tool {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  border: none;
  font-family: inherit;
  font-size: 1em;
  font-weight: 400;
  outline: transparent;
  border-radius: 0;
  border-radius: var(--doka-button-tool--radius);
  color: rgba(255, 255, 255, 0.9);
  color: var(--doka-button--color);
  display: flex;
  padding: 0 .75em 0 .5em;
  align-items: center;
  background-color: transparent;
  transition: transform .15s ease-out,background-color .25s ease-out,color .25s ease-out;
  transform: scale(1); }

.doka--root .doka--button-tool span {
  font-size: .875em;
  margin-left: .5em; }

.doka--root .doka--button-tool:focus, .doka--root .doka--button-tool:hover {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: var(--doka-effect--frost);
  backdrop-filter: var(--doka-effect--frost);
  background-color: rgba(255, 255, 255, 0.15);
  background-color: var(--doka-button--background); }

.doka--root .doka--button-tool:active {
  transition-duration: .25s;
  transform: scale(0.95); }

.doka--root [data-layout=compact] .doka--button-tool {
  padding: 0 .5em;
  border-radius: .25em;
  background-color: rgba(255, 255, 255, 0.1);
  background-color: var(--doka-button--background-dim);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: var(--doka-effect--frost);
  backdrop-filter: var(--doka-effect--frost);
  width: 2.6em;
  box-sizing: border-box;
  flex-grow: 0; }

.doka--root [data-layout=compact] .doka--button-tool:focus, .doka--root [data-layout=compact] .doka--button-tool:hover {
  background-color: rgba(255, 255, 255, 0.15);
  background-color: var(--doka-button--background); }

.doka--root [data-layout=compact] .doka--button-tool span {
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden; }

.doka--root .doka--button-zoom {
  position: static;
  margin: 0;
  padding: 0;
  border: none;
  font-family: inherit;
  font-size: 1em;
  outline: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 2.5;
  font-weight: 500;
  transition: transform .15s ease-out,background-color .25s ease-out,color .25s ease-out;
  border-radius: 9999em;
  height: 2.5em;
  width: 2.5em;
  color: rgba(0, 0, 0, 0.85);
  color: var(--doka-button-zoom--color);
  background-color: rgba(255, 255, 255, 0.85);
  background-color: var(--doka-button-zoom--background); }

.doka--root .doka--button-zoom span {
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden; }

.doka--root .doka--button-zoom:focus, .doka--root .doka--button-zoom:hover {
  background-color: rgba(255, 255, 255, 0.95);
  background-color: var(--doka-button-zoom-hover--background); }

@supports (-webkit-backdrop-filter: var(--doka-effect--frost)) or (backdrop-filter: var(--doka-effect--frost)) {
  .doka--root .doka--button-zoom {
    background-color: rgba(255, 255, 255, 0.6);
    background-color: var(--doka-button-zoom-effect--background);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: var(--doka-effect--frost);
    backdrop-filter: var(--doka-effect--frost); }
  .doka--root .doka--button-zoom:focus, .doka--root .doka--button-zoom:hover {
    background-color: rgba(255, 255, 255, 0.75);
    background-color: var(--doka-button-zoom-effect-hover--background); } }

.doka--root .doka--checkable {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 2.15625em;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.9);
  color: var(--doka-component--color);
  transform: scale(1); }

.doka--root .doka--checkable input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: 0;
  opacity: 0;
  outline: transparent; }

.doka--root .doka--checkable label {
  display: inline-block;
  cursor: pointer;
  transition: opacity .125s ease-out;
  font-size: .875em;
  margin: 0;
  color: inherit; }

.doka--root .doka--checkable input:active + label, .doka--root .doka--checkable input:focus + label {
  background-color: rgba(255, 255, 255, 0.15);
  background-color: var(--doka-component--background);
  border-radius: .5em; }

.doka--root .doka--checkable svg {
  display: block;
  margin: 0; }

.doka--root .doka--checkable path {
  transition: transform .1s ease-out; }

.doka--root .doka--checkable:active {
  transition-duration: .25s;
  transform: scale(0.925); }

.doka--root .doka--color {
  height: calc(100% - 3em);
  width: calc(100% - 2em);
  left: 1em;
  top: 1em; }

.doka--root .doka--color .doka--color-form {
  display: flex;
  align-items: flex-start;
  max-width: 45em;
  margin: 0 auto;
  padding: 1em 0 0;
  height: 6.5em;
  box-sizing: border-box;
  contain: layout size style; }

.doka--root .doka--color .doka--range-input {
  pointer-events: all;
  margin: 0 .5em;
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch;
  flex: 1; }

.doka--root .doka--color .doka--range-input label {
  color: inherit;
  margin-top: .25em;
  font-size: .75em;
  align-self: center;
  display: flex;
  align-items: center; }

.doka--root .doka--color .doka--range-input svg {
  display: inline-block;
  width: 1.25em;
  margin-right: .5em;
  max-height: 1.5em; }

.doka--root .doka--color .doka--range-input [stroke-width] {
  stroke-width: 2.5; }

.doka--root[data-style-viewport*=x-cramped] .doka--color {
  top: 2em;
  display: flex;
  flex-direction: column-reverse; }

.doka--root[data-style-viewport*=x-cramped] .doka--color-form {
  padding: .5em 0 0;
  height: 5em;
  flex-wrap: wrap;
  margin: 0 -.5em;
  align-self: center;
  width: 100%;
  max-width: 25em;
  padding: 0; }

.doka--root[data-style-viewport*=x-cramped] .doka--range-input {
  width: calc(50% - 1em);
  max-width: none;
  flex: auto;
  flex-direction: row;
  margin: 0 .25em; }

.doka--root[data-style-viewport*=x-cramped] .doka--range-input label {
  margin: 0;
  width: 1.25em;
  overflow: hidden; }

.doka--root[data-style-viewport*=x-cramped] .doka--range-input label svg {
  margin: 0; }

.doka--root[data-style-viewport*=x-cramped] .doka--range-input label span {
  display: none; }

.doka--root[data-style-viewport*=x-cramped] .doka--range-input:nth-child(2n) {
  flex-direction: row-reverse; }

@media (-ms-high-contrast: none) {
  .doka--color-form .doka--range-input, ::-ms-backdrop {
    flex: none;
    width: 10em; } }

.doka--root .doka--container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  touch-action: manipulation; }

.doka--root .doka--container[data-limit-overflow=true] {
  overflow: hidden; }

.doka--root[data-style-viewport*=x-cramped] .doka--container {
  flex-direction: column-reverse; }

.doka--root .doka--content {
  position: relative;
  flex: 1; }

.doka--root .doka--content > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.doka--root .doka--view-stack {
  z-index: 2; }

.doka--root .doka--image {
  z-index: 1; }

.doka--root .doka--instructions-bubble {
  color: #fff;
  color: var(--doka-instruction-bubble--color);
  background: radial-gradient(closest-side ellipse, rgba(0, 0, 0, 0.22) 20%, rgba(0, 0, 0, 0.21) 35%, rgba(0, 0, 0, 0.18) 50%, rgba(0, 0, 0, 0.1) 70%, rgba(0, 0, 0, 0.05) 80%, rgba(0, 0, 0, 0.01) 90%, transparent);
  background: var(--doka-instruction-bubble--background);
  filter: drop-shadow(0 0.125em 0.75em black);
  filter: drop-shadow(var(--doka-instruction-bubble--color-shadow));
  position: absolute;
  left: 0;
  top: 0;
  max-width: 15em;
  line-height: 1.5;
  text-align: center;
  padding: 3em 4em 4em;
  font-size: .75em;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center; }

.doka--root .doka--instructions-bubble svg:first-child {
  margin-bottom: .5em; }

.doka--root .doka--crop-mask {
  position: absolute;
  left: 0;
  top: 0;
  will-change: transform;
  pointer-events: none; }

.doka--root .doka--crop-rect {
  z-index: 2;
  position: relative; }

.doka--root .doka--crop-rect-corner {
  color: transparent;
  position: absolute;
  pointer-events: all;
  z-index: 3;
  padding: 0;
  margin: 0;
  border: none;
  text-indent: 0;
  width: 20px;
  height: 20px;
  left: -10px;
  top: -10px;
  will-change: transform; }

.doka--root .doka--crop-rect-corner:after {
  position: absolute;
  left: -1em;
  right: -1em;
  top: -1em;
  bottom: -1em;
  content: ""; }

.doka--root .doka--crop-rect-corner-ne, .doka--root .doka--crop-rect-corner-sw {
  cursor: nesw-resize !important; }

.doka--root .doka--crop-rect-corner-nw, .doka--root .doka--crop-rect-corner-se {
  cursor: nwse-resize !important; }

.doka--root[data-style-crop-corner=circle] .doka--crop-rect-corner {
  background: #fff;
  background: var(--doka-crop-corner--color);
  box-shadow: 0 0.0625em 0.125em rgba(0, 0, 0, 0.2);
  box-shadow: var(--doka-crop-corner--shadow);
  border-radius: 9999em;
  transition: background-color .25s ease-in-out; }

.doka--root[data-style-crop-corner=line] .doka--crop-rect-corner:before {
  position: absolute;
  width: 2.5em;
  height: 2.5em;
  pointer-events: none;
  transition: opacity .25s ease-out;
  content: ""; }

.doka--root[data-style-crop-corner=line] .doka--crop-rect-corner-nw:before {
  box-shadow: inset .25em .25em 0 0 #fff;
  box-shadow: inset 0.25em 0.25em 0 0 var(--doka-crop-corner--color);
  left: .4375em;
  top: .4375em; }

.doka--root[data-style-crop-corner=line] .doka--crop-rect-corner-ne:before {
  box-shadow: inset -.25em .25em 0 0 #fff;
  box-shadow: inset -0.25em 0.25em 0 0 var(--doka-crop-corner--color);
  right: .4375em;
  top: .4375em; }

.doka--root[data-style-crop-corner=line] .doka--crop-rect-corner-se:before {
  box-shadow: inset -.25em -.25em 0 0 #fff;
  box-shadow: inset -0.25em -0.25em 0 0 var(--doka-crop-corner--color);
  right: .4375em;
  bottom: .4375em; }

.doka--root[data-style-crop-corner=line] .doka--crop-rect-corner-sw:before {
  box-shadow: inset .25em -.25em 0 0 #fff;
  box-shadow: inset 0.25em -0.25em 0 0 var(--doka-crop-corner--color);
  left: .4375em;
  bottom: .4375em; }

.doka--root[data-style-crop-corner=line] .doka--crop-rect[data-indicator-size=none] .doka--crop-rect-corner:before {
  opacity: 0; }

.doka--root .doka--crop-rect-focal-line {
  background: rgba(255, 255, 255, 0.5);
  background: var(--doka-crop-focal-line--color);
  position: absolute;
  left: 0;
  top: 0;
  height: 100px;
  width: 100px;
  transform-origin: left top;
  z-index: 3;
  pointer-events: none;
  will-change: transform,opacity; }

.doka--root .doka--crop-rect-edge {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  border: 0;
  background: transparent;
  transform-origin: left top;
  z-index: 2;
  pointer-events: all;
  will-change: transform; }

.doka--root .doka--crop-rect-edge:focus {
  outline: transparent; }

.doka--root .doka--crop-rect-edge:focus:after {
  position: absolute;
  content: "";
  background-color: rgba(255, 255, 255, 0.5);
  background-color: var(--doka-crop-edge-focus--color); }

.doka--root .doka--crop-rect-edge-n, .doka--root .doka--crop-rect-edge-s {
  width: 100px;
  top: -20px;
  height: 40px;
  cursor: ns-resize !important; }

.doka--root .doka--crop-rect-edge-n:after, .doka--root .doka--crop-rect-edge-s:after {
  left: 0;
  right: 0;
  top: 17px;
  width: 100%;
  height: 7px; }

.doka--root .doka--crop-rect-edge-e, .doka--root .doka--crop-rect-edge-w {
  cursor: ew-resize !important;
  height: 100px;
  left: -20px;
  width: 40px; }

.doka--root .doka--crop-rect-edge-e:after, .doka--root .doka--crop-rect-edge-w:after {
  top: 0;
  bottom: 0;
  left: 17px;
  height: 100%;
  width: 7px; }

.doka--root[data-style-viewport*=touch][data-style-crop-corner=circle] .doka--crop-rect-corner:after {
  border-radius: 50%;
  opacity: 0;
  background: #fff;
  background: var(--doka-crop-corner-focus--color);
  box-shadow: 0 0 0 1em #fff;
  box-shadow: 0 0 0 1em var(--doka-crop-corner-focus--color); }

.doka--root[data-style-viewport*=touch][data-style-crop-corner=circle] .doka--crop-rect-corner:active:after {
  opacity: .25; }

.doka--root[data-style-viewport*=touch] .doka--crop-rect-edge:active {
  outline: transparent; }

.doka--root[data-style-viewport*=touch] .doka--crop-rect-edge:active:after {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  background-color: var(--doka-crop-edge-focus--color);
  content: ""; }

.doka--root[data-style-viewport*=x-cramped] .doka--crop-rect-corner {
  width: 16px;
  height: 16px;
  left: -8px;
  top: -8px; }

.doka--root[data-style-viewport*=x-cramped][data-style-crop-corner=line] .doka--crop-rect-corner-nw:before {
  left: .325em;
  top: .325em; }

.doka--root[data-style-viewport*=x-cramped][data-style-crop-corner=line] .doka--crop-rect-corner-ne:before {
  right: .325em;
  top: .325em; }

.doka--root[data-style-viewport*=x-cramped][data-style-crop-corner=line] .doka--crop-rect-corner-se:before {
  right: .325em;
  bottom: .325em; }

.doka--root[data-style-viewport*=x-cramped][data-style-crop-corner=line] .doka--crop-rect-corner-sw:before {
  left: .325em;
  bottom: .325em; }

.doka--root .doka--crop-resizer {
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background: transparent;
  pointer-events: none; }

.doka--root .doka--crop-resizer div {
  position: absolute;
  cursor: none;
  left: 0;
  top: 0;
  width: 44px;
  height: 44px;
  margin-left: -22px;
  margin-top: -22px;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 22px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  pointer-events: all;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: var(--doka-effect--frost);
  backdrop-filter: var(--doka-effect--frost);
  opacity: 0;
  transition: opacity .25s ease-out,visibility .25s ease-out; }

.doka--root .doka--crop-resizer[data-state=multi-touch] {
  visibility: visible;
  cursor: none;
  pointer-events: all; }

.doka--root .doka--crop-resizer[data-state=multi-touch] div {
  opacity: 1; }

.doka--root .doka--crop-rotator {
  position: relative;
  height: 3.5em;
  margin-top: -.5em;
  margin-bottom: .5em;
  z-index: 2;
  pointer-events: all; }

.doka--root .doka--crop-rotator-center {
  left: 50%;
  position: absolute;
  overflow: hidden;
  width: 2em;
  top: .325em;
  height: 1.5em;
  font-size: .75em;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0 0 0 -1em;
  background: transparent;
  opacity: .3;
  z-index: 2;
  color: inherit; }

.doka--root .doka--crop-rotator-center span {
  color: transparent; }

.doka--root .doka--crop-rotator-center:after {
  position: absolute;
  left: 50%;
  height: .85em;
  border-radius: 9999em;
  width: 1.5px;
  color: inherit;
  background: currentColor;
  transition: background-color .25s ease-in-out;
  margin-left: -.75px;
  top: 50%;
  margin-top: -.55em;
  pointer-events: none;
  content: ""; }

.doka--root .doka--crop-rotator-center:focus {
  outline: transparent;
  opacity: .7; }

.doka--root .doka--crop-rotator-bar {
  position: absolute;
  left: 1em;
  right: 1em;
  bottom: 0;
  top: 0;
  z-index: 1;
  cursor: ew-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.doka--root .doka--crop-rotator-line-mask {
  height: 100%;
  overflow: hidden;
  max-width: 30em;
  margin: 0 auto; }

.doka--root[data-style-viewport*=x-cramped] .doka--crop-rotator {
  margin-top: -.5em; }

.doka--root[data-style-viewport*=x-cramped] .doka--crop-rotator-line-mask {
  max-width: 14em; }

.doka--root .doka--crop-rotator-line {
  pointer-events: none;
  height: 100%; }

.doka--root .doka--crop-rotator-line svg {
  position: absolute;
  left: 50%;
  margin-left: -500px;
  width: 1000px;
  max-width: none; }

.doka--root .doka--crop-rotator-line text {
  font-weight: 400;
  font-size: 2px;
  opacity: .35; }

.doka--root .doka--crop-rotator-line circle {
  opacity: .4; }

.doka--root .doka--crop-rotator-line circle[r="0.5"] {
  opacity: .9; }

.doka--root .doka--crop-rotator:focus {
  outline: transparent; }

.doka--root .doka--crop-rotator:focus .doka--crop-rotator-line circle, .doka--root .doka--crop-rotator:focus .doka--crop-rotator-line text {
  fill: currentColor; }

@supports ((-webkit-mask-image: url("")) or (mask-image: url(""))) and (mix-blend-mode: screen) {
  .doka--root .doka--crop-rotator-line-mask, .doka--root[data-style-viewport*=x-cramped] .doka--crop-rotator-line-mask {
    max-width: 40em;
    margin: 0 auto;
    -webkit-mask: linear-gradient(90deg, transparent 15%, #000 30%, #000 70%, transparent 85%);
    mask: linear-gradient(90deg, transparent 15%, #000 30%, #000 70%, transparent 85%); } }

.doka--root .doka--crop-size {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  color: var(--doka-size-indicator--color);
  background: rgba(255, 255, 255, 0.95);
  background: var(--doka-size-indicator--background);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: var(--doka-effect--frost);
  backdrop-filter: var(--doka-effect--frost);
  border-radius: .25em;
  border-radius: var(--doka-size-indicator--radius);
  line-height: 1;
  white-space: nowrap;
  transition: background-color .25s ease-in-out,color .25s ease-in-out;
  transform-origin: center center;
  display: flex;
  align-items: center;
  pointer-events: none;
  font-variant-numeric: tabular-nums;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  font-family: Tahoma,Geneva,Verdana,sans-serif; }

.doka--root .doka--crop-size-info {
  font-size: .6875em;
  padding: .35em .625em;
  will-change: transform; }

.doka--root .doka--crop-resize-percentage {
  padding-right: .4375em;
  margin-right: -.25em;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid var(--doka-size-indicator--edge); }

.doka--root .doka--crop-resize-percentage:empty {
  display: none; }

.doka--root .doka--crop-size-multiply {
  opacity: .75;
  margin: 0 .175em; }

.doka--root[data-style-viewport*=x-cramped] .doka--crop-size-info {
  font-size: .5em;
  padding: .5em .875em; }

.doka--root[data-style-viewport*=x-cramped] .doka--crop-resize-percentage {
  padding-right: .4em; }

.doka--root .doka--crop-subject {
  position: relative;
  flex: 1 0 auto;
  margin: .25em 1.5em 1.5em;
  contain: layout size style; }

.doka--root[data-style-viewport*=x-cramped] .doka--crop-subject {
  margin: 0 2em 1em; }

.doka--root .doka--zoom-wrapper {
  position: relative;
  z-index: 2;
  pointer-events: all; }

.doka--root .doka--button-zoom {
  position: absolute;
  z-index: 2;
  left: -1.25em;
  top: -1.25em; }

.doka--root .doka--dropdown, .doka--root .doka--view-stack .doka--crop {
  display: flex;
  flex-direction: column; }

.doka--root .doka--dropdown {
  position: relative;
  justify-content: center; }

.doka--root .doka--dropdown > .doka--button {
  height: 100%; }

.doka--root .doka--dropdown-list {
  position: absolute;
  z-index: 3;
  padding: 0;
  top: 100%;
  margin: -.125em 0 0;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: .1625em;
  background-color: rgba(255, 255, 255, 0.85);
  background-color: var(--doka-component-alt--background);
  box-shadow: 0 0.75em 1em -0.75em rgba(0, 0, 0, 0.4), 0 1em 2em 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--doka-component-alt--shadow); }

.doka--root .doka--dropdown-list .doka--button {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 1em;
  outline: transparent;
  pointer-events: inherit;
  display: flex;
  align-items: center;
  white-space: nowrap;
  border: none;
  line-height: 2.5;
  margin: 3px;
  padding: 0 .75em 0 .5em;
  border-radius: .125em;
  color: rgba(0, 0, 0, 0.85);
  color: var(--doka-component-alt--color);
  background-color: transparent;
  transform: none; }

.doka--root .doka--dropdown-list .doka--button span {
  font-size: .875em;
  text-align: left;
  flex: 1; }

.doka--root .doka--dropdown-list .doka--button svg + span {
  margin-left: .5em; }

.doka--root .doka--dropdown-list .doka--button svg {
  display: inline-block;
  margin: 0; }

.doka--root .doka--dropdown-list .doka--button:active, .doka--root .doka--dropdown-list .doka--button:focus, .doka--root .doka--dropdown-list .doka--button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  background-color: var(--doka-dropdown-hover--background); }

.doka--root .doka--dropdown-list .doka--button:active:hover, .doka--root .doka--dropdown-list .doka--button:focus:hover {
  background-color: rgba(0, 0, 0, 0.15);
  background-color: var(--doka-dropdown-hover-active--background); }

.doka--root .doka--dropdown-list .doka--button:active {
  transform: none; }

.doka--root .doka--dropdown-list .doka--button:first-child, .doka--root .doka--dropdown-list .doka--button:last-child {
  border-radius: inherit; }

.doka--root .doka--dropdown-list .doka--button:first-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.doka--root .doka--dropdown-list .doka--button:last-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

.doka--root .doka--dropdown-list .doka--button + .doka--button {
  margin-top: -2px; }

.doka--root .doka--dropdown-list [aria-selected=true] {
  color: rgba(255, 255, 255, 0.9);
  color: var(--doka-dropdown-selected--color);
  background-color: rgba(0, 0, 0, 0.9);
  background-color: var(--doka-dropdown-selected--background); }

.doka--root .doka--dropdown-list [aria-selected=true]:active, .doka--root .doka--dropdown-list [aria-selected=true]:focus, .doka--root .doka--dropdown-list [aria-selected=true]:hover {
  background-color: rgba(0, 0, 0, 0.8);
  background-color: var(--doka-dropdown-selected-hover--background); }

.doka--root .doka--dropdown-list [aria-selected=true]:active:hover, .doka--root .doka--dropdown-list [aria-selected=true]:focus:hover {
  background-color: rgba(0, 0, 0, 0.6);
  background-color: var(--doka-dropdown-selected-active-hover--background); }

@supports (-webkit-backdrop-filter: var(--doka-effect--frost)) or (backdrop-filter: var(--doka-effect--frost)) {
  .doka--root .doka--dropdown-list {
    background-color: rgba(255, 255, 255, 0.6);
    background-color: var(--doka-component-alt-effect--background);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: var(--doka-effect--frost);
    backdrop-filter: var(--doka-effect--frost); } }

.doka--root[data-style-viewport*=x-cramped] .doka--dropdown {
  position: static; }

.doka--root[data-style-viewport*=x-cramped] .doka--dropdown-list {
  position: absolute;
  min-width: auto;
  top: 3em;
  left: 3em;
  right: 3em;
  max-width: 15em;
  margin: 0 auto; }

@media (-ms-high-contrast: none) {
  .doka--root .doka--dropdown-list, ::-ms-backdrop {
    min-width: 10em; } }

.doka--root .doka--edit-status {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  contain: strict;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: opacity .25s ease-out;
  background: rgba(255, 255, 255, 0.1);
  background: var(--doka-status--background); }

.doka--root .doka--edit-status .doka--status-bubble {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center; }

.doka--root .doka--edit-status[data-view-status=busy] {
  pointer-events: all;
  opacity: 1; }

.doka--root .doka--edit-status:empty {
  display: none; }

.doka--root .doka--editor {
  height: 100%;
  width: 100%;
  flex: 1 0 auto;
  max-width: 80em;
  max-width: var(--doka-editor--max-width);
  max-height: 60em;
  max-height: var(--doka-editor--max-height);
  opacity: 0; }

.doka--root .doka--filter-list {
  display: inline-flex;
  align-items: flex-start;
  list-style: none;
  margin: 0;
  padding: 0; }

.doka--root .doka--filter-list li {
  margin: .25em .5em;
  padding: 0; }

.doka--root .doka--filter-list li:first-child {
  margin-left: 0; }

.doka--root .doka--filter-list li:last-child {
  margin-right: 0; }

@media (-ms-high-contrast: none) {
  .doka--root .doka--filter-list li, ::-ms-backdrop {
    flex: none;
    width: 4em; } }

.doka--root .doka--filter-tile.doka--filter-tile {
  position: relative;
  border-radius: 0;
  border-radius: var(--doka-filter-tile--radius); }

.doka--root .doka--filter-tile.doka--filter-tile:before {
  display: block;
  padding-top: 100%;
  content: "";
  height: 0; }

.doka--root .doka--filter-tile.doka--filter-tile[style*="transform:translate3d(0px, 0px, 0)"] {
  transform: none !important; }

.doka--root .doka--filter-tile.doka--filter-tile div, .doka--root .doka--filter-tile.doka--filter-tile label {
  transition: box-shadow .125s ease-out,color .125s ease-out; }

.doka--root .doka--filter-tile.doka--filter-tile label {
  position: absolute;
  color: rgba(255, 255, 255, 0.9);
  color: var(--doka-component--color);
  text-align: center;
  font-size: .6875em;
  top: calc(100% + .5em);
  width: 100%;
  left: 0;
  padding: 0; }

.doka--root .doka--filter-tile.doka--filter-tile > div {
  margin-bottom: .5em;
  z-index: 2;
  border-radius: 0;
  border-radius: var(--doka-filter-tile--radius);
  overflow: hidden;
  box-shadow: 0 0 0.75em rgba(0, 0, 0, 0.1);
  pointer-events: none; }

.doka--root .doka--filter-tile.doka--filter-tile > div canvas {
  z-index: 1; }

.doka--root .doka--filter-tile.doka--filter-tile > div div {
  z-index: 2; }

.doka--root .doka--filter-tile.doka--filter-tile canvas, .doka--root .doka--filter-tile.doka--filter-tile div, .doka--root .doka--filter-tile.doka--filter-tile input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-width: auto;
  min-height: auto;
  font-size: 1em; }

.doka--root .doka--filter-tile.doka--filter-tile input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  border-radius: 0;
  opacity: 0;
  outline: transparent;
  pointer-events: all; }

.doka--root .doka--filter-tile.doka--filter-tile input:checked ~ label {
  color: #ffd843;
  color: var(--doka-filter-tile-selected--color); }

.doka--root .doka--filter-tile.doka--filter-tile input:checked ~ div {
  box-shadow: 0 0.5px 0 1px rgba(0, 0, 0, 0.2), 0 0 0 4px #ffd843;
  box-shadow: 0 0.5px 0 1px rgba(0, 0, 0, 0.2), 0 0 0 4px var(--doka-filter-tile-selected--color); }

.doka--root .doka--filter-tile.doka--filter-tile input:focus ~ label {
  color: #ffd843;
  color: var(--doka-filter-tile-selected--color); }

.doka--root .doka--filter {
  height: calc(100% - 3em);
  width: calc(100% - 2em);
  left: 1em;
  top: 1em; }

.doka--root .doka--filter .doka--filter-list {
  padding-bottom: 1.5em; }

.doka--root .doka--filter-list {
  padding: 0 2em; }

.doka--root .doka--filter-tile {
  flex: none;
  width: 4em;
  scroll-snap-align: start; }

.doka--root[data-style-viewport*=x-cramped] .doka--filter-tile {
  width: 2.875em; }

.doka--root[data-style-viewport*=x-cramped] .doka--filter {
  display: flex;
  flex-direction: column-reverse;
  top: 2em; }

.doka--root[data-style-viewport*=x-cramped] .doka--filter .doka--filter-list {
  padding: 0 1em;
  height: 5em; }

.doka--root .doka--image-gl {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.doka--root .doka--image-gl canvas {
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  color: var(--doka-editor-outline--color);
  background: rgba(0, 0, 0, 0.99);
  background: var(--doka-editor--background-color);
  max-width: none; }

.doka--root .doka--image-gl[tabindex="0"] canvas {
  pointer-events: all;
  cursor: move; }

.doka--root .doka--image-gl:after {
  content: "";
  transition: opacity .15s ease-in-out;
  opacity: 0;
  position: absolute;
  left: calc(50% - 1.3em);
  top: calc(50% - 1.3em);
  width: 2.75em;
  height: 2.75em;
  pointer-events: none;
  border-radius: 9999em;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 2em;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='rgba(255,255,255,.9)' stroke-width='1.5'%3E%3Crect x='8.5' y='3.5' width='7' height='7' rx='2'/%3E%3Crect x='1.5' y='10.5' width='7' height='7' rx='2'/%3E%3Crect x='8.5' y='10.5' width='7' height='7' rx='2'/%3E%3Crect x='15.5' y='10.5' width='7' height='7' rx='2'/%3E%3C/g%3E%3C/svg%3E"); }

.doka--root .doka--image-gl[data-show-interaction-indicator=true]:focus:after {
  opacity: 1; }

.doka--root .doka--button-destroy-shape {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  margin: 0;
  border: none;
  font-family: inherit;
  outline: transparent;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 2.5;
  font-weight: 500;
  transition: background-color .25s ease-out,color .25s ease-out;
  border-radius: 9999em;
  padding: 0 1em;
  font-size: .75em;
  opacity: 0;
  color: rgba(0, 0, 0, 0.85);
  color: var(--doka-button-zoom--color);
  background-color: rgba(255, 255, 255, 0.85);
  background-color: var(--doka-button-zoom--background); }

.doka--root .doka--button-destroy-shape span {
  pointer-events: none; }

.doka--root .doka--button-destroy-shape:focus, .doka--root .doka--button-destroy-shape:hover {
  background-color: rgba(255, 255, 255, 0.95);
  background-color: var(--doka-button-zoom-hover--background); }

.doka--root .doka--button-destroy-shape[data-active=true] {
  opacity: 1 !important; }

.doka--root .doka--button-destroy-shape[data-active=false] {
  opacity: 0 !important;
  pointer-events: none;
  transition: none; }

@supports (-webkit-backdrop-filter: var(--doka-effect--frost)) or (backdrop-filter: var(--doka-effect--frost)) {
  .doka--root .doka--button-destroy-shape {
    background-color: rgba(255, 255, 255, 0.6);
    background-color: var(--doka-button-zoom-effect--background);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: var(--doka-effect--frost);
    backdrop-filter: var(--doka-effect--frost); }
  .doka--root .doka--button-destroy-shape:focus, .doka--root .doka--button-destroy-shape:hover {
    background-color: rgba(255, 255, 255, 0.75);
    background-color: var(--doka-button-zoom-effect-hover--background); } }

.doka--root .doka--image-markup, .doka--root .doka--image-markup-clip {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden; }

.doka--root .doka--image-markup-clip {
  contain: strict; }

.doka--root .doka--image-markup {
  pointer-events: all; }

.doka--root .doka--image-markup svg {
  display: block;
  contain: strict;
  height: 100%;
  width: 100%; }

.doka--root .doka--image-markup[data-active=false] {
  pointer-events: none; }

.doka--root .doka--image-markup[data-util=draw] svg {
  cursor: crosshair; }

.doka--root .doka--image-markup[data-util=draw] .doka--shape-group {
  pointer-events: none; }

.doka--root .doka--image-markup text {
  white-space: pre; }

.doka--root .doka--image-markup image {
  transition: opacity .25s ease-in-out; }

.doka--root .doka--image-markup input {
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 1 !important;
  text-align: left !important;
  text-indent: 0 !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  box-shadow: none !important;
  pointer-events: none;
  outline: none;
  color: #fff;
  z-index: 1; }

.doka--root .doka--image-markup input::-ms-clear {
  display: none; }

.doka--root .doka--image-markup input:focus {
  pointer-events: all; }

.doka--root .doka--image-markup input[hidden] {
  display: none; }

.doka--root .doka--image-markup[data-active=true] {
  pointer-events: all !important; }

.doka--root .doka--manipulator-group {
  pointer-events: none; }

.doka--root .doka--manipulator-group .doka--hitbox {
  pointer-events: all; }

.doka--root .doka--manipulator-group circle:not(.doka--hitbox) {
  fill: #2990ff;
  fill: var(--doka-markup-manipulator--fill);
  stroke: #fff;
  stroke: var(--doka-markup-manipulator--stroke);
  stroke-width: 2;
  r: 6; }

.doka--root .doka--manipulator-group path, .doka--root .doka--manipulator-group rect {
  stroke-width: 1.5;
  stroke: rgba(255, 255, 255, 0.75);
  stroke: var(--doka-markup-manipulator--line); }

.doka--root .doka--manipulator-group[is-bright-color=true] path {
  stroke: rgba(0, 0, 0, 0.75);
  stroke: var(--doka-markup-manipulator--line-inverted); }

.doka--root .doka--image {
  pointer-events: none; }

.doka--root[data-style-layout-mode~=fullscreen] {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2147483647;
  opacity: 0; }

.doka--root[data-style-layout-mode~=fullscreen]:focus {
  outline: transparent; }

.doka--root[data-style-layout-mode~=fullscreen] .doka--edit-status {
  position: fixed; }

.doka--root[data-style-layout-mode~=fullscreen][data-style-fullscreen-safe-area=bottom][data-style-fullscreen=true] {
  padding-bottom: 40px;
  box-sizing: border-box; }

@media (min-height: 635px) {
  .doka--root[data-style-layout-mode~=fullscreen][data-style-fullscreen-safe-area=bottom][data-style-fullscreen=true] {
    padding-bottom: 80px; } }

.doka--parent::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0.85);
  background-color: var(--doka-scrollbar-rail--color); }

.doka--parent::-webkit-scrollbar-track {
  background: transparent; }

.doka--parent::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.75);
  background: var(--doka-scrollbar-thumb--color);
  border-radius: 9999rem;
  border: 3px solid rgba(0, 0, 0, 0.85);
  border: 3px solid var(--doka-scrollbar-rail--color); }

@media (min-width: 56rem) and (min-height: 40rem) {
  .doka--root[data-style-layout-mode~=modal] {
    background: rgba(0, 0, 0, 0.8);
    background: var(--doka-modal--overlay);
    transition: background-color .25s ease-in-out; }
  .doka--root[data-style-layout-mode~=modal]:before {
    content: none; }
  .doka--root[data-style-layout-mode~=modal] .doka--editor {
    position: relative;
    overflow: hidden;
    background: #000;
    background: var(--doka-modal--background);
    box-shadow: 0 1em 4em rgba(0, 0, 0, 0.25), 0 0.5em 2em rgba(0, 0, 0, 0.25);
    box-shadow: var(--doka-modal--shadow);
    transition: background-color .25s ease-in-out;
    width: 70vw;
    width: var(--doka-modal--width);
    height: 80vh;
    height: var(--doka-modal--height);
    max-width: 56rem;
    max-width: var(--doka-modal--max-width);
    max-height: 40rem;
    max-height: var(--doka-modal--max-height); }
  .doka--root[data-style-layout-mode~=modal] .doka--edit-status {
    position: absolute; } }

.doka--root[data-style-layout-mode~=preview] {
  background: transparent; }

.doka--root[data-style-layout-mode~=preview]:before {
  content: none; }

.doka--root[data-style-layout-mode~=preview] .doka--content {
  position: relative;
  z-index: 1; }

.doka--root[data-style-layout-mode~=preview] .doka--editor {
  max-width: none;
  max-height: none; }

.doka--root[data-style-layout-mode~=preview] .doka--image-outline, .doka--root[data-style-layout-mode~=preview] .doka--utils {
  display: none; }

.doka--root[data-style-layout-mode~=preview] .doka--menu {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  pointer-events: none !important; }

.doka--root[data-style-layout-mode~=preview] .doka--menu .doka--button {
  pointer-events: all; }

.doka--root[data-style-layout-mode~=preview] .doka--button, .doka--root[data-style-layout-mode~=preview] .doka--crop-rotator {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: var(--doka-effect--frost);
  backdrop-filter: var(--doka-effect--frost);
  box-shadow: 0 0.125em 0.5em rgba(0, 0, 0, 0.325); }

.doka--root[data-style-layout-mode~=preview] .doka--button-app:not(.doka--button-action-confirm) {
  background-color: rgba(0, 0, 0, 0.8);
  background-color: var(--doka-component--background-dark); }

.doka--root[data-style-layout-mode~=preview] .doka--button-app:not(.doka--button-action-confirm):focus, .doka--root[data-style-layout-mode~=preview] .doka--button-app:not(.doka--button-action-confirm):hover {
  background-color: rgba(0, 0, 0, 0.9);
  background-color: var(--doka-component--background-darker); }

.doka--root[data-style-layout-mode~=preview] .doka--processing-indicator p {
  color: rgba(255, 255, 255, 0.9);
  color: var(--doka-editor--color);
  background-color: rgba(0, 0, 0, 0.8);
  background-color: var(--doka-component--background-dark); }

.doka--root[data-style-layout-mode~=preview][data-style-viewport*=x-cramped] .doka--processing-indicator {
  bottom: 0; }

.doka--root[data-style-layout-mode~=preview] .doka--toolbar {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 !important;
  z-index: 3; }

.doka--root[data-style-layout-mode~=preview] .doka--toolbar .doka--button-tool {
  background-color: rgba(0, 0, 0, 0.8);
  background-color: var(--doka-component--background-dark);
  border-radius: .25em; }

.doka--root[data-style-layout-mode~=preview] .doka--toolbar .doka--button-tool:focus, .doka--root[data-style-layout-mode~=preview] .doka--toolbar .doka--button-tool:hover {
  background-color: rgba(0, 0, 0, 0.9);
  background-color: var(--doka-component--background-darker); }

.doka--root[data-style-layout-mode~=preview] .doka--crop-rect {
  mix-blend-mode: difference;
  height: 100%;
  pointer-events: none;
  -webkit-mask: radial-gradient(ellipse closest-side, #000, transparent);
  mask: radial-gradient(ellipse closest-side, #000, transparent); }

.doka--root[data-style-layout-mode~=preview] .doka--crop-rotator {
  position: absolute;
  bottom: 0;
  left: 12rem;
  right: 12rem;
  border-radius: 9999rem;
  background-color: rgba(0, 0, 0, 0.8);
  background-color: var(--doka-component--background-dark); }

.doka--root[data-style-layout-mode~=preview] .doka--crop-size {
  transform: none !important;
  right: 1em;
  top: 1em;
  left: auto; }

.doka--root[data-style-layout-mode~=preview] .doka--crop-rotator-line-mask {
  max-width: 100%; }

.doka--root[data-style-layout-mode~=preview] .doka--crop-subject {
  margin: 0; }

.doka--root[data-style-layout-mode~=preview] .doka--image-container[style*="opacity:1"] ~ .doka--image-overlay {
  background-color: rgba(0, 0, 0, 0.9);
  background-color: var(--doka-component--background-darker);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }

.doka--root[data-style-layout-mode~=preview][data-style-viewport~=x-cramped] .doka--crop-rotator {
  display: none; }

.doka--root[data-style-layout-mode~=preview][data-style-viewport~=x-spacious] .doka--crop-rotator {
  left: 16rem;
  right: 16rem; }

.doka--root[data-style-layout-mode~=preview] .doka--crop-rect-corner, .doka--root[data-style-layout-mode~=preview] .doka--crop-rect-edge {
  display: none; }

.doka--root .doka--menu {
  display: flex;
  box-sizing: border-box;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  position: relative;
  pointer-events: all !important;
  transition: background-color .125s ease-in-out,color .125s ease-in-out;
  overflow: hidden; }

.doka--root .doka--menu .doka--button-action-confirm {
  margin-left: auto; }

.doka--root:not([data-style-viewport~=x-cramped]) .doka--menu {
  height: 4.75em;
  padding: 0 1.25em; }

.doka--root:not([data-style-viewport~=x-cramped]) .doka--utils {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 5em;
  right: 5em; }

.doka--root:not([data-style-viewport~=x-cramped]) .doka--button-action-cancel {
  margin-right: 1em; }

.doka--root:not([data-style-viewport~=x-cramped]) .doka--button-action-reset {
  margin-right: auto; }

.doka--root[data-style-viewport~=flow-controls]:not([data-style-viewport~=x-cramped]) .doka--utils {
  left: 12em;
  left: var(--doka-menu--offset);
  right: 12em;
  right: var(--doka-menu--offset); }

.doka--root[data-style-viewport~=x-cramped] .doka--menu {
  margin-top: -.75em;
  height: 3.25em; }

.doka--root[data-style-viewport~=x-cramped][data-style-viewport~=multi-util] .doka--utils {
  position: absolute;
  left: 3.25em;
  right: 3.25em;
  top: 0;
  bottom: 0; }

.doka--root[data-style-viewport~=x-cramped][data-style-viewport~=multi-util] .doka--menu {
  overflow: hidden;
  margin: 0 1em 1em;
  padding: 0 .6125em;
  border-radius: 9999em;
  background: rgba(255, 255, 255, 0.1);
  background: var(--doka-component--background-dim);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: var(--doka-effect--frost);
  backdrop-filter: var(--doka-effect--frost); }

.doka--root[data-style-viewport~=x-cramped][data-style-viewport~=multi-util] .doka--button-action-reset {
  position: absolute;
  left: .75em;
  top: .6875em;
  width: 2.5em;
  height: 2.5em; }

.doka--root[data-view=resize] .doka--menu {
  background: rgba(0, 0, 0, 0.8) !important;
  background: var(--doka-component--background-dark) !important; }

.doka--root[data-style-viewport~=single-util][data-style-viewport~=no-flow-controls] .doka--button-action-reset {
  position: absolute;
  top: .3125em;
  left: calc(50% - 1.125em); }

.doka--root .doka--range-input {
  display: block; }

.doka--root .doka--range-input button {
  position: absolute;
  top: calc(50% + .5em);
  height: 1.325em;
  width: 2em;
  bottom: 100%;
  left: calc(50% - 1em);
  padding: 0;
  margin: 0;
  border: none;
  font-size: 1em;
  overflow: hidden;
  color: transparent;
  background: transparent;
  outline: transparent; }

.doka--root .doka--range-input button:after {
  content: "";
  pointer-events: none;
  position: absolute;
  top: calc(50% - .5em);
  left: calc(50% - .06125em);
  width: .125em;
  height: .5em;
  background: rgba(255, 255, 255, 0.2);
  background: var(--doka-range-input-recenter--background);
  border-radius: .9999em;
  bottom: 0; }

.doka--root .doka--range-input[data-centered=true] button:after {
  background: rgba(255, 255, 255, 0.2);
  background: var(--doka-range-input-centered--background); }

.doka--root .doka--range-input input {
  -webkit-appearance: none;
  display: block;
  font-size: 1em;
  width: 100%;
  min-width: auto;
  min-height: auto;
  position: absolute;
  left: .5em;
  width: calc(100% - 1em);
  margin: 0;
  padding: 0;
  line-height: 0;
  top: 0;
  height: 100%;
  background: transparent;
  border: none;
  border-radius: 0; }

.doka--root .doka--range-input input::-webkit-slider-runnable-track {
  background: transparent; }

.doka--root .doka--range-input input::-webkit-slider-thumb {
  -webkit-appearance: none;
  line-height: 0;
  margin: 0;
  padding: 0;
  border: none;
  width: .625em;
  height: .625em;
  border-radius: .9999em;
  transition: box-shadow .25s ease-in-out,background-color .25s ease-in-out;
  background: #fff;
  background: var(--doka-range-input-thumb--color); }

.doka--root .doka--range-input input::-moz-range-thumb {
  line-height: 0;
  margin: 0;
  padding: 0;
  border: none;
  width: .625em;
  height: .625em;
  border-radius: .9999em;
  transition: box-shadow .25s ease-in-out,background-color .25s ease-in-out;
  background: #fff;
  background: var(--doka-range-input-thumb--color); }

.doka--root .doka--range-input input::-ms-thumb {
  line-height: 0;
  margin: 0;
  padding: 0;
  border: none;
  width: .625em;
  height: .625em;
  border-radius: .9999em;
  transition: box-shadow .25s ease-in-out,background-color .25s ease-in-out;
  background: #fff; }

.doka--root .doka--range-input input::-ms-ticks-before, .doka--root .doka--range-input input::-ms-tooltip {
  display: none; }

.doka--root .doka--range-input input::-ms-ticks-after {
  display: none; }

.doka--root .doka--range-input input::-ms-track {
  color: transparent;
  border: none;
  background: transparent; }

.doka--root .doka--range-input input::-ms-fill-lower, .doka--root .doka--range-input input::-ms-fill-upper {
  background: transparent; }

.doka--root .doka--range-input input:focus {
  outline: transparent; }

.doka--root .doka--range-input input:focus::-moz-range-thumb {
  box-shadow: 0 0 0 0.5em rgba(255, 255, 255, 0.15);
  box-shadow: 0 0 0 0.5em var(--doka-range-input-thumb-hover--color); }

.doka--root .doka--range-input input:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 0.5em rgba(255, 255, 255, 0.15);
  box-shadow: 0 0 0 0.5em var(--doka-range-input-thumb-hover--color); }

.doka--root .doka--range-input span:hover input::-moz-range-thumb {
  box-shadow: 0 0 0 0.5em rgba(255, 255, 255, 0.15);
  box-shadow: 0 0 0 0.5em var(--doka-range-input-thumb-hover--color); }

.doka--root .doka--range-input span:hover input::-webkit-slider-thumb {
  box-shadow: 0 0 0 0.5em rgba(255, 255, 255, 0.15);
  box-shadow: 0 0 0 0.5em var(--doka-range-input-thumb-hover--color); }

.doka--root .doka--range-input-inner {
  display: block;
  position: relative;
  flex: 1;
  min-height: 2em; }

.doka--root .doka--range-input-inner:after {
  content: "";
  position: absolute;
  left: .5em;
  right: .5em;
  top: calc(50% - .125em);
  height: .25em;
  background-color: rgba(255, 255, 255, 0.2);
  background-color: var(--doka-range-input--background);
  transition: background-color .25s ease-in-out;
  border-radius: .9999em; }

.doka--root .doka--resize-form {
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  padding: 1em 0;
  margin: 0;
  height: 2.5em; }

.doka--root .doka--resize-form fieldset, .doka--root .doka--resize-form legend {
  border: none;
  padding: 0;
  margin: 0; }

.doka--root .doka--resize-form legend {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0; }

.doka--root .doka--resize-form fieldset {
  margin: 0;
  padding: 0;
  flex: 1 0 auto;
  max-width: 17em; }

.doka--root .doka--resize-form .doka--size-input {
  margin: 0;
  width: calc(50% - 1.125em); }

.doka--root .doka--resize-form .doka--checkable {
  margin: 0 .325em;
  vertical-align: bottom; }

.doka--root .doka--resize-form .doka--checkable label {
  padding: .3125em 0; }

.doka--root .doka--resize-form button {
  margin-right: -3.25em;
  margin-left: 1em;
  font-size: .875em; }

.doka--root .doka--resize-form button svg {
  width: 1.25em;
  height: 1.25em; }

.doka--root[data-view=resize] .doka--resize-form {
  background-color: rgba(0, 0, 0, 0.8);
  background-color: var(--doka-component--background-dark); }

.doka--root[data-style-viewport~=flow-controls][data-style-viewport~=single-util]:not([data-style-viewport~=x-cramped]) .doka--resize-form {
  margin-top: -4.5em;
  background: none; }

.doka--root[data-style-viewport*=touch] .doka--resize-form button {
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden; }

.doka--root .doka--view-stack .doka--resize {
  height: calc(100% - 1em) !important; }

.doka--root .doka--size-input {
  position: relative;
  display: inline-flex;
  cursor: text; }

.doka--root .doka--size-input input::-webkit-inner-spin-button, .doka--root .doka--size-input input::-webkit-outer-spin-button {
  -webkit-appearance: none; }

.doka--root .doka--size-input input::-ms-clear {
  display: none; }

.doka--root .doka--size-input input, .doka--root .doka--size-input label {
  padding: .625em .5em;
  text-indent: .25em;
  font-size: .875em;
  font-weight: 400;
  line-height: inherit;
  cursor: inherit; }

.doka--root .doka--size-input label {
  pointer-events: none;
  position: absolute;
  right: 0;
  cursor: text;
  flex: 1;
  white-space: nowrap;
  text-align: right;
  padding-left: 0;
  padding-right: .75em;
  color: rgba(255, 255, 255, 0.9);
  color: var(--doka-editor--color); }

.doka--root .doka--size-input input {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: textfield;
  margin: 0;
  min-height: 0;
  min-width: 7em;
  height: auto;
  width: 100%;
  font-variant-numeric: tabular-nums;
  outline: transparent;
  border-radius: .5em;
  box-shadow: none;
  border: none;
  color: rgba(255, 255, 255, 0.9);
  color: var(--doka-editor--color);
  background-color: rgba(255, 255, 255, 0.15);
  background-color: var(--doka-component--background);
  transition: background-color .1s ease-in-out,color .1s ease-in-out; }

.doka--root .doka--size-input input:focus {
  transition: inherit;
  border: inherit;
  background-color: inherit;
  box-shadow: 0 0 0 2px currentColor; }

.doka--root .doka--size-input input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.3) !important;
  color: var(--doka-editor--color-dim) !important; }

.doka--root .doka--size-input input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.3) !important;
  color: var(--doka-editor--color-dim) !important; }

.doka--root .doka--size-input input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3) !important;
  color: var(--doka-editor--color-dim) !important; }

.doka--root .doka--size-input input::placeholder {
  color: rgba(255, 255, 255, 0.3) !important;
  color: var(--doka-editor--color-dim) !important; }

.doka--root .doka--size-input input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3) !important;
  color: var(--doka-editor--color-dim) !important; }

@supports (-webkit-backdrop-filter: var(--doka-effect--frost)) or (backdrop-filter: var(--doka-effect--frost)) {
  .doka--root .doka--size-input input {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: var(--doka-effect--frost);
    backdrop-filter: var(--doka-effect--frost); } }

.doka--root {
  box-sizing: content-box;
  position: relative;
  overflow: hidden;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 100%;
  width: 100%;
  line-height: normal;
  font-size: 16px;
  font-size: var(--doka-font-size);
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
  font-family: var(--doka-font-family);
  font-weight: 450;
  text-align: left;
  text-rendering: optimizeLegibility;
  direction: ltr;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  color: var(--doka-editor--color);
  background: rgba(0, 0, 0, 0.99);
  background: var(--doka-editor--background-color);
  contain: strict;
  transition: background-color .25s ease-in-out,color .25s ease-in-out; }

.doka--root:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.15) 0, rgba(255, 255, 255, 0) 80%);
  background: radial-gradient(ellipse at center, var(--doka-editor--background-ellipse-inner-color) 0, var(--doka-editor--background-ellipse-outer-color) 80%); }

.doka--root label {
  margin: 0 !important; }

.doka--root label:after, .doka--root label:before {
  content: none !important; }

.doka--root .doka--markup {
  display: flex;
  flex-direction: column; }

.doka--root .doka--markup .doka--toolbar {
  display: inline-flex;
  margin: 0 auto;
  padding: 1.25em 0; }

.doka--root .doka--markup-utils.doka--markup-utils {
  border: none;
  padding: 0; }

.doka--root .doka--markup-utils.doka--markup-utils ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex; }

.doka--root .doka--markup-utils.doka--markup-utils li {
  margin: 0 .75em; }

.doka--root .doka--markup-utils.doka--markup-utils input {
  display: none; }

.doka--root .doka--markup-utils.doka--markup-utils label {
  display: flex;
  align-items: center;
  pointer-events: all;
  padding: 0 .75em 0 .5em;
  line-height: inherit;
  margin: 0;
  height: 2.5em;
  color: rgba(255, 255, 255, 0.9);
  color: var(--doka-button--color); }

.doka--root .doka--markup-utils.doka--markup-utils input:checked + label {
  color: #ffd843;
  color: var(--doka-filter-tile-selected--color); }

.doka--root .doka--toolbar[data-layout=compact] .doka--markup-utils li {
  margin: 0 .3125em; }

.doka--root .doka--markup-tools {
  box-sizing: content-box;
  display: flex;
  flex-wrap: wrap;
  margin: auto 1em 0;
  padding: .3125em 0 .75em;
  justify-content: center;
  align-items: flex-end;
  max-height: 3em;
  min-height: 2.0625em; }

.doka--root .doka--markup-tools[data-active=true] {
  pointer-events: all !important; }

.doka--root .doka--markup-tools .doka--button span {
  text-align: left; }

.doka--root .doka--markup-tools .doka--dropdown > .doka--button {
  color: rgba(255, 255, 255, 0.9);
  color: var(--doka-button--color);
  background-color: rgba(255, 255, 255, 0.15);
  background-color: var(--doka-button--background);
  border-radius: 9999em;
  line-height: 1.75em;
  margin: 0 .25em;
  font-size: .925em; }

.doka--root .doka--markup-tools .doka--dropdown > .doka--button > span {
  margin-left: .3125em; }

.doka--root .doka--markup-tools .doka--dropdown {
  margin-top: .5em; }

.doka--root .doka--markup-tools [data-active=false] > .doka--button {
  display: none; }

.doka--root .doka--markup-tools .doka--markup-color {
  margin-top: -.875em; }

.doka--root .doka--markup-tools .doka--markup-color[data-active=false] {
  display: none; }

.doka--root .doka--markup-color.doka--markup-color {
  display: flex;
  justify-content: center; }

.doka--root .doka--markup-color.doka--markup-color, .doka--root .doka--markup-color.doka--markup-color ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.doka--root .doka--markup-color.doka--markup-color ul {
  display: flex;
  justify-content: center; }

.doka--root .doka--markup-color.doka--markup-color li {
  padding: 0;
  margin: 0 .125em; }

.doka--root .doka--markup-color.doka--markup-color li input {
  display: none; }

.doka--root .doka--markup-color.doka--markup-color label {
  display: block;
  overflow: hidden;
  color: transparent;
  width: 1.5em;
  height: 1.5em;
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: inherit;
  border-radius: 9999em;
  transition: box-shadow .125s ease-out;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: inherit; }

.doka--root .doka--markup-color.doka--markup-color .doka--color-input input[data-selected=true] + label, .doka--root .doka--markup-color.doka--markup-color input:checked + label {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.5), 0 0 0 2px #fff; }

.doka--root .doka--markup-color.doka--markup-color .doka--color-input {
  margin: 0 .125em; }

.doka--root .doka--color-input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5em;
  height: 1.5em;
  border-radius: 9999em;
  color: rgba(255, 255, 255, 0.9);
  color: var(--doka-button--color);
  background-color: rgba(255, 255, 255, 0.15);
  background-color: var(--doka-button--background); }

.doka--root .doka--color-input label {
  overflow: hidden;
  background-image: radial-gradient(rgba(255, 255, 255, 0.3) 33%, rgba(255, 255, 255, 0) 36%), url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23FECD09' d='M12 0h12v24H12z'/%3E%3Cpath fill='%2366AF01' d='M0 12h24v12H0z'/%3E%3Cpath fill='%237300CD' d='M0 0h12v24H0z'/%3E%3Cpath fill='%23E12224' d='M0 0h12v12H0z'/%3E%3Cpath fill='%23FF7D00' d='M12 12L24 0H12z'/%3E%3Cpath fill='%23B400AA' d='M0 12V0l12 12z'/%3E%3Cpath fill='%23039695' d='M12 24h12L12 12z'/%3E%3Cpath fill='%23465FCD' d='M12 24H0l12-12z'/%3E%3C/g%3E%3C/svg%3E"); }

.doka--root .doka--color-input input {
  position: absolute;
  opacity: 0;
  -webkit-appearance: none;
  border: 0;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 1em;
  min-width: 0;
  min-height: 0; }

.doka--root .doka--color-input span {
  position: absolute;
  pointer-events: none; }

.doka--root .doka--color-input .doka--color-visualizer {
  opacity: 0;
  width: .375em;
  height: .375em;
  border-radius: 1em;
  box-shadow: 0 0 1px 1px #fff, 0 0 0 1.5px rgba(0, 0, 0, 0.7);
  z-index: 1; }

.doka--root .doka--color-input .doka--color-visualizer[style] {
  opacity: 1; }

.doka--root .doka--color-input .doka--color-brightness {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 9999em;
  background: #000;
  box-shadow: inset 0 0 0.75em rgba(255, 255, 255, 0.25), 0 0 1px #000;
  opacity: 0; }

.doka--root[data-style-viewport~=x-cramped] .doka--markup-color {
  width: 100%; }

.doka--root .doka--scroller {
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-x: none;
  touch-action: pan-x;
  text-align: center;
  pointer-events: all;
  width: 40em;
  margin: 0 auto 1em;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
  scrollbar-color: var(--doka-scroller-thumb--color) transparent; }

@supports (-ms-ime-align: auto) {
  .doka--root .doka--scroller {
    overflow-x: hidden; } }

.doka--root .doka--scroller[data-drag-state=dragging] button, .doka--root .doka--scroller[data-drag-state=dragging] input {
  pointer-events: none !important; }

.doka--root .doka--scroller::-webkit-scrollbar {
  height: .375em;
  background-color: transparent;
  background-color: var(--doka-scroller-rail--color); }

.doka--root .doka--scroller::-webkit-scrollbar-track {
  background: transparent; }

.doka--root .doka--scroller::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 9999rem;
  border: 3px solid transparent;
  border: 3px solid var(--doka-scroller-rail--color);
  transition: background-color .25s ease-in-out; }

@media (pointer: fine) and (hover: hover) {
  .doka--root .doka--scroller:hover {
    scrollbar-width: thin; }
  .doka--root .doka--scroller:hover::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    background-color: var(--doka-scroller-thumb--color); } }

.doka--root[data-style-viewport*=x-cramped] .doka--filter-scroller {
  -ms-scroll-snap-type: proximity;
  scroll-snap-type: proximity;
  -ms-scroll-snap-type: x proximity;
  scroll-snap-type: x proximity;
  scroll-padding: 2em;
  margin-bottom: 0; }

.doka--root .doka--scroller {
  scrollbar-base-color: #111;
  scrollbar-face-color: #333;
  scrollbar-3dlight-color: #111;
  scrollbar-highlight-color: #111;
  scrollbar-track-color: #111;
  scrollbar-arrow-color: #111;
  scrollbar-shadow-color: #111; }

@supports ((-webkit-mask-image: url("")) or (mask-image: url(""))) and (mix-blend-mode: screen) {
  .doka--root .doka--scroller {
    margin: 0 auto 1em;
    -webkit-mask: linear-gradient(90deg, transparent 0, #000 2em, #000 calc(100% - 2em), transparent), linear-gradient(0deg, #000 8px, transparent 0);
    mask: linear-gradient(90deg, transparent 0, #000 2em, #000 calc(100% - 2em), transparent), linear-gradient(0deg, #000 8px, transparent 0); }
  .doka--root[data-style-viewport*=x-cramped] .doka--scroller {
    -webkit-mask: linear-gradient(90deg, transparent 0, #000 1em, #000 calc(100% - 1em), transparent);
    mask: linear-gradient(90deg, transparent 0, #000 1em, #000 calc(100% - 1em), transparent); } }

.doka--root .doka--sticker {
  height: calc(100% - 3em);
  width: calc(100% - 2em);
  left: 1em;
  top: 1em; }

.doka--root .doka--sticker-list {
  display: inline-flex;
  align-items: flex-start;
  list-style: none;
  margin: 0;
  padding: 0 2em .5em; }

.doka--root .doka--sticker-list li {
  margin: .25em .3125em;
  padding: 0; }

.doka--root .doka--sticker-list li:first-child {
  margin-left: 0; }

.doka--root .doka--sticker-list li:last-child {
  margin-right: 0; }

.doka--root .doka--sticker-list button {
  background-color: rgba(255, 255, 255, 0.1);
  background-color: var(--doka-component--background-dim);
  padding: .25em; }

.doka--root .doka--sticker-list img, .doka--root .doka--sticker-list svg {
  display: block;
  width: 2em;
  height: 2em;
  -o-object-fit: contain;
  object-fit: contain;
  pointer-events: none;
  border-radius: .25em; }

.doka--root .doka--sticker-list span {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  pointer-events: none; }

.doka--root[data-style-viewport*=x-cramped] .doka--sticker {
  display: flex;
  flex-direction: column-reverse;
  top: 2em; }

.doka--root[data-style-viewport*=x-cramped] .doka--sticker .doka--sticker-list {
  padding: 0 1em;
  height: 3.5em; }

@media (-ms-high-contrast: none) {
  .doka--root .doka--sticker-list li, ::-ms-backdrop {
    flex: none;
    width: 2.5em; } }

.doka--root .doka--status-progress {
  display: none;
  background-color: rgba(0, 0, 0, 0.2);
  background-color: var(--doka-status-bubble--color-dim);
  border-radius: 50%;
  pointer-events: none; }

.doka--root .doka--status-bubble {
  pointer-events: none;
  will-change: transform;
  text-align: center; }

.doka--root .doka--status-bubble .doka--status-bubble-inner {
  position: relative;
  display: inline-block;
  color: rgba(0, 0, 0, 0.8);
  color: var(--doka-status-bubble--color);
  background-color: rgba(255, 255, 255, 0.75);
  background-color: var(--doka-status-bubble--background);
  box-shadow: 0 0.125em 1em rgba(0, 0, 0, 0.2);
  box-shadow: var(--doka-status-bubble--shadow);
  transition: background-color .25s ease-in-out,color .25s ease-in-out;
  font-size: .875em;
  max-width: 80%;
  margin: 0 auto;
  border-radius: 1em;
  line-height: 1.5;
  text-align: center;
  padding: .1875em 1em .25em; }

.doka--root .doka--status-bubble p {
  display: inline;
  margin: 0;
  padding: 0; }

.doka--root .doka--status-bubble .doka--status-progress:not([data-value="0"]) {
  position: absolute;
  display: block;
  right: .35em;
  top: .35em;
  width: 1.25em;
  height: 1.25em; }

.doka--root .doka--status-bubble .doka--status-progress:not([data-value="0"]) + p, .doka--root .doka--status-bubble button + p {
  padding-right: 1.5em; }

.doka--root .doka--status-bubble button {
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 0;
  border: none;
  font-family: inherit;
  font-size: 1em;
  font-weight: 400;
  outline: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  border-radius: 9999em;
  width: 18px;
  height: 18px;
  color: rgba(255, 255, 255, 0.75);
  color: var(--doka-status-bubble--background);
  background-color: rgba(0, 0, 0, 0.8);
  background-color: var(--doka-status-bubble--color);
  position: absolute;
  right: .375em;
  top: .35em; }

.doka--root .doka--status-bubble button svg {
  width: .875em;
  height: .875em; }

.doka--root .doka--status-bubble button:after {
  content: "";
  position: absolute;
  left: -1em;
  right: -1em;
  top: -1em;
  bottom: -1em; }

@supports (-webkit-backdrop-filter: var(--doka-effect--frost)) or (backdrop-filter: var(--doka-effect--frost)) {
  .doka--root .doka--status-bubble-inner {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: var(--doka-effect--frost);
    backdrop-filter: var(--doka-effect--frost);
    background: rgba(255, 255, 255, 0.5);
    background: var(--doka-status-bubble-effect--background); } }

.doka--root .doka--toolbar {
  z-index: 3;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 1.25em 0;
  pointer-events: none;
  margin: 0 2em; }

.doka--root .doka--toolbar > * {
  pointer-events: all;
  margin: 0 .75em;
  height: 2.5em; }

.doka--root .doka--toolbar svg {
  display: inline-block;
  flex-shrink: 0; }

.doka--root .doka--toolbar svg * {
  transition: opacity .25s ease-out,transform .5s ease-out; }

.doka--root .doka--toolbar .doka--button {
  white-space: nowrap;
  flex-shrink: 0; }

.doka--root[data-style-viewport~=flow-controls][data-style-viewport~=single-util]:not([data-style-viewport~=x-cramped]) .doka--toolbar {
  margin: -4.875em 11em 0; }

.doka--root .doka--toolbar[data-layout=compact] > * {
  margin: 0 .5em; }

.doka--root[data-style-viewport~=x-cramped] .doka--toolbar {
  padding: 1em 0; }

.doka--root[data-style-viewport~=x-cramped] .doka--toolbar > * {
  margin: 0 .25em; }

.doka--root .doka--utils {
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 0;
  overflow: hidden; }

.doka--root .doka--utils > * {
  pointer-events: all; }

.doka--root .doka--utils > * + * {
  margin-left: 1px; }
